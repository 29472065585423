<template>
  <div>
    <md-button class="md-raised md-icon-button mg-left-0" :to="{ name: 'Box' }">
      <font-awesome-icon icon="arrow-left" />
    </md-button>
    <box-form @onSubmit="onSubmit" :disabled="submitting" />
  </div>
</template>

<script>
import { Box } from "../../common/api/api.service";
import BoxForm from "../../components/box/BoxForm.vue";
import { ALERT, TOOLBAR_TITLE } from "../../store/actions.types";
export default {
  name: "BoxCreate",
  components: {
    BoxForm,
  },
  data() {
    return {
      submitting: false,
    };
  },

  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "box_create");
  },

  methods: {
    onSubmit(box) {
      Box.post(box)
        .then(() => {
          this.$router.push({ name: "Box" });
        })
        .catch(() => {
          this.$store.dispatch(ALERT, { message: "error" });
        })
        .finally(() => {
          this.submitting = true;
        });
    },
  },
};
</script>
